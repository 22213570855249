import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const Nav = styled.nav`
  background-color: #344a06;
  height: 70px;
  display: flex;
  justify-content: space-between;
  /* padding: 0 50px 0 20px; */
  z-index: 100;
  box-shadow: -5px 5px 10px black;
`;

export const NavLink = styled(Link)`
  color: white;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  text-decoration: none;
  text-shadow: 7px 7px 10px black;

  &.active {
    color: "#344906";
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const NavLogoBackground = styled.div`
  border-radius: 0 0 200px 0;
  background-color: #344a06;
  height: 130px;
  min-width: 300px;
  transform: translate(0, 0px);
  box-shadow: -5px 5px 10px black;
  z-index: 1;
`;

export const NavLogoImage = styled.img`
  height: 100px;
  padding: 10px;
`;
