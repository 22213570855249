import React from "react";
import {
  Nav,
  NavLink,
  NavMenu,
  NavLogoBackground,
  NavLogoImage,
} from "./elements";

import BlueMountains from "../../assets/mountains-blue.svg";

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavLogoBackground>
          <NavLogoImage src={BlueMountains} alt="blue mountains logo" />
        </NavLogoBackground>
        <NavMenu>
          <NavLink to="/" activeStyle>
            Home
          </NavLink>
          <NavLink to="/get-involved" activeStyle>
            Get Involved
          </NavLink>
          <NavLink to="/our-team" activeStyle>
            Our Team
          </NavLink>
          <NavLink to="/give" activeStyle>
            Give
          </NavLink>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
