import React from "react";
import "./css/Home.css";

// image assets
import BackgroundImage from "../assets/sanctuary-subdued.png";
import LogoImage from "../assets/logo.svg";

const Home = () => {
  return (
    <>
      <div>
        <img src={BackgroundImage} alt="sanctuary" className="background" />
        <img src={LogoImage} alt="CasperNaz logo" className="logo" />
      </div>
    </>
  );
};

export default Home;
