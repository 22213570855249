import React from "react";

const GetInvolved = () => {
  return (
    <div>
      <h1>GetInvolved Page</h1>
    </div>
  );
};

export default GetInvolved;
