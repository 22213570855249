import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import GetInvolved from "./pages/GetInvolved";
import OurTeam from "./pages/OurTeam";
import Give from "./pages/Give";

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/get-involved" component={GetInvolved} />
        <Route path="/our-team" component={OurTeam} />
        <Route path="/give" component={Give} />
      </Switch>
    </Router>
  );
}

export default App;
